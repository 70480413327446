.whatsapp {
  position: fixed;
  width: 36px;
  height: 36px;
  bottom: 110px;
  right: 25px;
  z-index: 9999;
}
.whatsapp img {
  width: 100%;
  height: auto;
}

.freedesign {
  position: fixed;
  width: 65px;
  height: 65px;
  bottom: 20px;
  right: 10px;
  z-index: 9999;
}
.freedesign img {
  width: 100%;
  height: auto;
}

.footer {
  background-image: url(../images/footer-bg.png);
  padding: 20px 0px;
  color: #fff;
}
.footer-logo {
  text-align: center;
}
.footer-logo img {
  height: 100px;
  width: auto;
}
.footer-copy-wrap {
  display: flex;
  width: 100%;
  border-top: 1px solid #fff;
  padding: 10px 0px 0px 0px;
}
.footer-copy-1 {
  flex: 1;
}
.footer-copy-2 {
  width: 130px;
  text-align: right;
}
.footer-copy-2 img {
  height: 20px;
  width: auto;
  margin-right: 10px;
}
.footer-nav {
  padding: 25px 0px 25px 0px;
}
.footer-nav ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-nav ul li {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style: none;
  font-size: 20px;
}
.footer-nav ul li a {
  color: #fff;
  cursor: pointer;
}
.footer-nav ul li a:hover {
  color: #0480c4;
}

.footer-new-wrap {
  display: flex;
  width: 100%;
  margin: 10px 0px 20px 0px;
}
.footer-new-1 {
  width: 32%;
}
.footer-new-2 {
  flex: 1;
}
.footer-new-3 {
  width: 32%;
}
.footer-new-wrap ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.footer-new-wrap ul li {
  margin: 0px;
  padding: 6px 0px;
  list-style: none;
}
.footer-new-wrap ul li a {
  color: #fff;
  text-decoration: none;
}
.w-50-50 li {
  width: 45%;
}

.footer-new-wrap ul li a:hover {
  color: #0480c4 !important;
}
.footer-a-1 {
  padding: 0px 0px 0px 30px;
  background-repeat: no-repeat;
  background-position: left top 4px;
  margin-bottom: 15px;
}
.f-phone {
  background-image: url(../images/f-phone.png);
}
.f-mail {
  background-image: url(../images/f-mail.png);
}
.f-location {
  background-image: url(../images/f-location.png);
}
.footer-new-wrap h4 {
  margin: 0px 0px 20px 0px;
  padding: 0px;
  font-family: "Carot Sans";
  font-size: 30px;
  font-weight: 300;
}
@media screen and (max-width: 830px) {
  .footer-nav ul li a {
    text-align: center;
    padding: 10px 0px;
  }
  .footer-copy-wrap {
    flex-wrap: wrap;
  }
  .footer-copy-1,
  .footer-copy-2 {
    flex: inherit;
    width: 100%;
    text-align: center;
    padding: 5px 0px;
  }
}

@media screen and (max-width: 767px) {
  .footer-new-1,
  .footer-new-2,
  .footer-new-3,
  .footer-new-4 {
    padding-bottom: 15px;
  }
  .footer-new-wrap h4 {
    margin: 0px 0px 12px 0px;
    font-size: 20px;
  }
  .footer-new-wrap ul li {
    margin: 0px;
    padding: 4px 0px;
    list-style: none;
    font-size: 13px;
  }

  .freedesign {
    display: none;
  }
  .whatsapp {
    bottom: 20px;
  }
}
@media screen and (max-width: 560px) {
  .footer-new-wrap {
    flex-wrap: wrap;
  }
  .footer-new-1,
  .footer-new-2,
  .footer-new-3 {
    width: 100%;
    margin-bottom: 10px;
  }
}
